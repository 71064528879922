<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Contractor Employer</h1>
              </div>
                <DataTable loading-text="Loading data. Please wait...."
                           :loading="pendingContractEmployers ? true:false"
                           :headers="headers" :items="pendingContractEmployers" :approve="approve" />
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import DataTable from '@/components/ui/TableData.vue'
import {
  FETCH_PENDING_CONTRACTEMPLOYER,
  APPROVE_CONTRACTEMPLOYER
} from '@/store/action-type'

export default {
  components: {
    DataTable
  },
  data () {
    return {
      search: '',
      alertMessage: '',
      alert: false,
      alertType: '',
      isLoading: false,
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'URL',
          value: 'url'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
        {
          text: 'Contact Person Name',
          value: 'contactPersonName'
        },
        {
          text: 'Contact Person Email',
          value: 'contactPersonEmail'
        },
        {
          text: 'Contact Person Phone',
          value: 'contactPersonPhone'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    pendingContractEmployers () {
      return this.$store.getters.pendingContractEmployers
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    approve (item) {
      this.$store
        .dispatch(APPROVE_CONTRACTEMPLOYER, item)
        .then((result) => {
          this.showAlertMessage(
            'Approve Contractor Employer successful',
            'success'
          )
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approve Contractor Employer failed', 'error')
        })
    },
    deleteItem (item) {
      console.log(item)
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_PENDING_CONTRACTEMPLOYER)
      .then(() => {})
      .catch((error) => {
        console.log(error)
        this.showAlertMessage('There are no items pending your approval', 'warning')
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
